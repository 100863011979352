import React from 'react'
import Title from '../../atoms/HeadingWithUnderbar'
import styled from 'styled-components'
import colors from '../../tokens/colors'

const Component = ({ title, subTitle, data }) => {
  console.log ( data ); //★
  const list_data = [];
  Object.keys(data).forEach( key => {
    let val =
      key === 'url' ? 
       <dd><a href={data[key]} target='_blank' rel="noopener noreferrer">{data[key]}</a> </dd> :
      key === 'e-mail' ? 
        <dd><a href={'mailto:'+data[key]} target='_blank' rel="noopener noreferrer">{data[key]}</a> </dd> : 
      <dd>{data[key]}</dd>
    list_data.push (
      <div>
        <dt>{key}</dt>
        {val}  
      </div>
    )
  });
  return (
    <React.Fragment>
      <Title title={title} subTitle={subTitle} margin={60} />
      <List>
        {list_data}
      </List>
    </React.Fragment>
  )
}

const List = styled.dl`
  padding: 0 0 60px;
  > div {
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
  }
  dt {
    display: inline-block;
    width: 30%;
    font-size: 1.4rem;
    vertical-align: top;
    font-weight: bold;
  }
  dd {
    display: inline-block;
    width: 70%;
    font-size: 1.4rem;
    padding-left: 10px;
    white-space: pre-wrap;
    a{
      color: ${colors.cl_red};
    }
  }
`

export default Component
