import React from 'react'
import Title from '../atoms/HeadingWithUnderbar'
import styled from 'styled-components'
import colors from '../tokens/colors'
import { flex, alignVertical } from '../tokens/positionings'
import breakpoints from '../breakpoints'

const Component = ({ target }) => {
  return (
    <Form>
      <div>
        {(() => {
          if (target) {
            return (
              <Title
                title="Entry"
                subTitle="エントリー"
                margin={60}
                color="#FFFFFF"
              />
            )
          } else {
            return (
              <React.Fragment>
                <Title
                  title="Contact"
                  subTitle="お問い合わせ"
                  margin={60}
                  color="#FFFFFF"
                />
                <p className="tel">
                  お電話のお問い合わせ:{' '}
                  <a href="tel:0762351110">076-235-1110</a>
                </p>
              </React.Fragment>
            )
          }
        })()}
        <form
          name="contact"
          className="contact--form"
          method="POST"
          data-netlify="true"
          action="/complete"
        >
          <input type="hidden" name="form-name" value="contact"/>  
          {(() => {
            if (target) return <h5>「{target}」にエントリーする</h5>
          })()}
          <input type="target" name="target" hidden value={target} />
          <dl>
            <div>
              <dt>
                お名前 <span className="hissu">必須</span>
              </dt>
              <dd>
                <input type="text" name="name" recuired />
              </dd>
            </div>

            <div>
              <dt>
                フリガナ <span className="hissu">必須</span>
              </dt>
              <dd>
                <input type="text" name="kana" recuired />
              </dd>
            </div>

            <div>
              <dt>学校名 / 会社名 / 組織名</dt>
              <dd>
                <input type="text" name="school" />
              </dd>
            </div>

            <div>
              <dt>学部・学科 / 部署</dt>
              <dd>
                <input type="text" name="division" />
              </dd>
            </div>

            <div>
              <dt>
                メールアドレス <span className="hissu">必須</span>
              </dt>
              <dd>
                <input type="email" name="email" recuired />
              </dd>
            </div>

            <div>
              <dt>電話番号</dt>
              <dd>
                <input type="tel" name="tel" />
              </dd>
            </div>

            <div className="expanded">
              <dt>お問い合わせ内容</dt>
              <dd>
                <textarea name="text"></textarea>
              </dd>
            </div>
          </dl>
          <button type="submit">上記内容で送信</button>
        </form>
      </div>
    </Form>
  )
}


const Form = styled.section`
  background-color: ${colors.cl_red};
  padding: 80px;
  ${breakpoints.lessThan('pc')`{
  padding: 80px 20px;
}`}
  .tel {
    text-align: center;
    padding-bottom: 40px;
    color: ${colors.cl_white};
    font-size: 1.8rem;
    font-weight: bold;
    a {
      text-decoration: underline;
    }
  }
  > div {
    max-width: 980px;
    margin: 0 auto;
    > form {
      background-color: white;
      padding: 40px;
      text-align: center;
      ${breakpoints.lessThan('pc')`{
      padding: 40px 10px 10px;
    }`}
      h5 {
        text-align: center;
        font-size: 1.8rem;
      }
      button {
        background-color: ${colors.cl_main};
        padding: 10px 20px;
        color: white;
        border-radius: 4px;
        ${breakpoints.lessThan('pc')`{
        padding: 20px 40px;;
        margin-bottom: 40px;
        font-size: 1.4rem;
      }`}
        :hover {
          background-color: ${colors.cl_red};
        }
      }
      dl {
        ${flex('space-between')};
        padding: 20px 0;
        ${breakpoints.lessThan('pc')`{
        ${alignVertical};
      }`}
        div {
          width: 48%;
          padding: 10px;
          ${breakpoints.lessThan('pc')`{
        width: 100%;
        }`}
          dt, dd {
            width: 100%;
            text-align: left;
            font-size: 1.2rem;
            padding: 4px 0;
            ${flex('space-between')};
            span {
              color: ${colors.cl_red};
            }
          }
          input {
            width: 100%;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e5e5e5;
          }
        }
        div.expanded {
          width: 100%;
        }
        textarea {
          width: 100%;
          height: 100px;
          padding: 10px;
          border-radius: 2px;
          border: 1px solid #e5e5e5;
        }
      }
    }
  }
`

export default Component
