import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import { SectionTitle } from '../components/tokens/fontStyles'
import Articles from '../components/organisms/ArticlesContainer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import breakpoints from '../components/breakpoints.js'
import Table from '../components/modules/tables/DataList'
import ContactForm from '../components/organisms/ContactForm'

export const query = graphql`
  query($id: String!) {
    microcmsOffers(id: { eq: $id }) {
      id
      title
      image {
        url
      }
      method {
        title
      }
      articles {
        title
        text
        image {
          url
        }
      }
      e_salary
      e_description
      e_status
      e_raise
      e_bonus
      e_insurance
      e_interview
      e_holiday
      e_process
      e_place
      e_time
      e_remarks
    }
  }
`
const IndexPage = ({ data }) => {
  data = data.microcmsOffers

  // const filterData = keys => {
  //   let result = {}
  //   keys.forEach(key => {
  //     result[key] = data[key]
  //   })
  //   return result
  // }
  const table_data = {
    雇用形態: data.e_status,
    仕事内容: data.e_description,
    月給: data.e_salary,
    保険: data.e_insurance,
    賞与: data.e_bonus,
    昇給: data.e_raise,
    勤務時間: data.e_time,
    休日: data.e_holiday,
    勤務地: data.e_place,
    面接地: data.e_interview,
    選考プロセス: data.e_process,
    備考: data.e_remarks,
  }
  return (
    <Layout>
      <SEO title={data.title} />
      <KeyVisual title="" image={data.image.url} />
      <Title>{data.title}</Title>
      <Wrapper>
        <Articles data={data.articles} />
        <Table data={table_data} title='Recuirements' subTitle='募集要項' />
      </Wrapper>
      <ContactForm target={data.title} />
    </Layout>
  )
}


const Title = styled.h2`
  ${SectionTitle}
  max-width: 980px;
  margin: 80px auto;
  text-align: center !important;
  ${breakpoints.lessThan('pc')`
padding: 0 20px 20px;
`}
`

const Wrapper = styled.section`
  max-width: 980px;
  margin: 0 auto;
  padding: 0px 40px;
  ${breakpoints.lessThan('pc')`
padding: 0 20px;
`}
`
export default IndexPage
